import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";
import { Box, Heading, Flex, Text, Button } from "rebass/styled-components";

const ImgMarkupTwoCol = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.supTitle && <Text variant="supTitle">{data.supTitle}</Text>}
      {data.title && (
        <Heading as="h2" variant="variants.blockTitleInline">
          {data.title}
        </Heading>
      )}
      <Box>
        {data.rows.map((item, i) => (
          <Flex
            key={i}
            variant="grid"
            alignItems="center"
            flex="1"
            pt={[10, 10, 11]}
            flexDirection={
              (item.textPosition == "left" && ["column-reverse", "column-reverse", "row"]) ||
              (item.textPosition == "right" && ["column-reverse", "column-reverse", "row-reverse"])
            }
            sx={{
              "&:first-child": {
                pt: 0
              }
            }}
          >
            <Box
              width={[1, 1, 1 / 2]}
              variant="gridItem"
              pl={item.textPosition == "left" ? [3, 3, 6] : [3, 3, 6, 6]}
              pr={item.textPosition == "right" ? [3, 3, 6, 6] : [3, 3, 6, 6]}
            >
              {item.contentTitle && (
                <Heading as="h3" variant="h4">
                  {item.contentTitle}
                </Heading>
              )}
              <Box
                sx={{
                  "& > div > ul:last-child": {
                    mb: 0,
                    "li:last-child": {
                      mb: 0
                    }
                  },
                  "p:last-child": {
                    mb: 0
                  },
                  "h3, h4": {
                    "&:first-child": {
                      mt: 0
                    }
                  }
                }}
              >
                <Markdown>{item.content}</Markdown>
              </Box>
              {item.cta && (
                <Box mt={4}>
                  <Link href={item.cta.href}>
                    <Button variant="base">
                      {item.cta.label}
                      <Icon icon="arrow-next" />
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} variant="gridItem" pb={[4, 4, 0]}>
              <Box
                maxWidth="600px"
                ml="auto"
                mr="auto"
                sx={{
                  borderRadius: "big",
                  overflow: "hidden"
                }}
              >
                <CustomImage img={item.image} />
              </Box>
            </Box>
          </Flex>
        ))}
      </Box>
    </Wrapper>
  );
};

ImgMarkupTwoCol.propTypes = {
  data: PropTypes.shape({
    supTitle: PropTypes.any,
    title: PropTypes.any,
    rows: PropTypes.any
  })
};
export default ImgMarkupTwoCol;

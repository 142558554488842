import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { LangContext } from "../../Layout/langContext";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import { Box, Heading, Flex, Text, Button } from "rebass/styled-components";
const Products = ({ data, uuid }) => {
  const activeProduct = uuid || false;
  const { currentLanguage } = useContext(LangContext);
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "ProductTemplate" } } }
          sort: { fields: [frontmatter___weight], order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                slug
                uuid
                imageCover {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 250) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                imageBanner {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 350) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <Wrapper data={data}>
      {activeProduct &&
        (!data.comingSoon ? (
          <Box mb={[10, 10, 11]}>
            {data.imageBanner && (
              <>
                <Text variant="supTitle" textAlign="center">
                  {currentLanguage == "en" ? "We produce" : "Nous produisons"}
                </Text>
                <Heading as="h2" variant="variants.blockTitleCenter" mb={8}>
                  {currentLanguage == "en" ? "Beverages for all" : "Des boissons pour tous"}
                </Heading>
                <Box
                  sx={{
                    borderRadius: "big",
                    overflow: "hidden"
                  }}
                >
                  <CustomImage img={data.imageBanner} />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box mb={[10, 10, 11]}>
            <Heading as="h2" variant="variants.blockTitleCenter" mb={8}>
              {currentLanguage == "en" ? "Coming soon" : "Bientôt disponible"}
            </Heading>
          </Box>
        ))}
      <Box>
        <Text variant="supTitle">
          {data.supTitle ? data.supTitle : currentLanguage == "en" ? "See more" : "Voir plus"}
        </Text>
        <Heading as="h2" variant="variants.blockTitleInline">
          {data.title ? data.title : currentLanguage == "en" ? "Other products" : "Autres produits"}
        </Heading>
        <Flex variant="grid">
          {allMarkdownRemark.edges.map(
            (item, i) =>
              item.node.frontmatter.uuid != activeProduct &&
              item.node.fields.slug.endsWith("." + currentLanguage + "/") && (
                <Box key={i} variant="gridItem" width={[1, 1 / 2, 1 / 3]} mb={[4, 4, 6]}>
                  <Box
                    sx={{
                      borderRadius: "big",
                      overflow: "hidden",
                      position: "relative",
                      minHeight: "150px",
                      bg: "grayLight",
                      "&:before": {
                        content: "''",
                        display: "block",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        backgroundImage: "linear-gradient(transparent, #000000)",
                        zIndex: 1,
                        opacity: ".7"
                      },
                      a: {
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        p: 4,
                        textDecoration: "none",
                        zIndex: 2,
                        "&:before": {
                          content: "''",
                          display: "block",
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          backgroundColor: "primary",
                          zIndex: 1,
                          opacity: ".75",
                          transition: "all 150ms ease"
                        },
                        "& > *": {
                          position: "relative",
                          zIndex: 2
                        },
                        h3: {
                          paddingBottom: 0,
                          transition: "all 300ms ease-in-out"
                        },
                        ".btn-wrapper": {
                          maxHeight: 0,
                          transition: "max-height 500ms ease",
                          overflow: "hidden"
                        },
                        "&:hover": {
                          "&:before": {
                            top: 0
                          },
                          h3: {
                            paddingBottom: 3
                          },
                          ".btn-wrapper": {
                            maxHeight: "200px"
                          }
                        }
                      }
                    }}
                  >
                    <Link
                      href={
                        currentLanguage == "fr"
                          ? "/fr" + item.node.frontmatter.slug
                          : item.node.frontmatter.slug
                      }
                    >
                      <Text as="h3" color="white" sx={{ textTransform: "none" }}>
                        {item.node.frontmatter.title}
                      </Text>
                      <Box className="btn-wrapper">
                        <Button variant="outlineWhite">
                          {currentLanguage == "fr" ? "Découvrir" : "Discover"}
                        </Button>
                      </Box>
                    </Link>
                    <CustomImage img={item.node.frontmatter.imageCover} />
                  </Box>
                </Box>
              )
          )}
        </Flex>
      </Box>
    </Wrapper>
  );
};

Products.propTypes = {
  uuid: PropTypes.any,
  data: PropTypes.shape({
    supTitle: PropTypes.any,
    title: PropTypes.any,
    imageCover: PropTypes.any,
    imageBanner: PropTypes.any,
    comingSoon: PropTypes.any
  })
};

export default Products;

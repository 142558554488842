import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import Link from "../../UI/Link";
import Markdown from "../../UI/Markdown";
import Icon from "../../UI/Icon";

const Infos = () => {
  var telLink = "tel:";
  var mailTo = "mailto:";
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { frontmatter: { title: { eq: "footer" } } }) {
          nodes {
            frontmatter {
              title
              companyName
              addressFooter
              phoneFooter
              mailFooter
              waterWebsite
            }
          }
        }
      }
    `
  );
  const infos = allMarkdownRemark.nodes[0].frontmatter;
  return (
    <Box>
      <Heading as="h2" variant="h4">
        {infos.companyName}
      </Heading>
      <Flex mt={4}>
        <Box color="primary" pr={2}>
          <Icon icon="poi" size={20} />
        </Box>
        <Markdown>{infos.addressFooter}</Markdown>
      </Flex>
      <Box mt={4}>
        <a href={(telLink = telLink + infos.phoneFooter.replace(/[- )(]/g, ""))}>
          <Flex alignItems="center">
            <Flex alignItems="center" color="primary" pr={2}>
              <Icon icon="phone" size={20} />
            </Flex>
            <Text>{infos.phoneFooter}</Text>
          </Flex>
        </a>
      </Box>
      <Box mt={4}>
        <a href={mailTo + infos.mailFooter}>
          <Flex alignItems="center">
            <Flex alignItems="center" color="primary" pr={2}>
              <Icon icon="mail" size={20} />
            </Flex>
            <Text>{infos.mailFooter}</Text>
          </Flex>
        </a>
      </Box>
      <Box mt={4}>
        <a href={infos.waterWebsite} target="_blank" rel="noreferrer">
          <Flex alignItems="center">
            <Flex alignItems="center" color="primary" pr={2}>
              <Icon icon="link-external" size={20} />
            </Flex>
            <Text>
              {infos.waterWebsite
                .split("//")
                .pop()
                .replace(/^\/|\/$/g, "")}
            </Text>
          </Flex>
        </a>
      </Box>
    </Box>
  );
};

export default Infos;

import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Button, Box, Flex } from "rebass/styled-components";
import Link from "../../UI/Link";
import CustomImage from "../../UI/CustomImage";
import Icon from "../../UI/Icon";
import Slider from "react-slick";
import { withTheme } from "styled-components";

const Slideshow = ({ data, theme }) => {
  const slider = React.useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "0"
  };
  return (
    <Wrapper
      data={data}
      sx={{
        "& > div": {
          px: "0"
        }
      }}
    >
      <Box
        sx={{
          position: "relative",
          ".slick-slider": {
            ".slick-list": {
              overflow: "visible",
              ".slick-track": {
                display: "flex",
                alignItems: "stretch",
                ".slick-slide": {
                  height: "auto",
                  float: "none",
                  div: {
                    height: "100%",
                    display: "block !important"
                  },
                  a: {
                    background: "black",
                    opacity: ".6"
                  },
                  "&.slick-current": {
                    a: {
                      opacity: "0"
                    }
                  }
                }
              }
            },
            ".slick-arrow": {
              transform: "none",
              width: "100%",
              height: "100%",
              textIndent: "-9999px",
              overflow: "hidden",
              top: "0",
              zIndex: "10",
              background: "transparent",
              "&.slick-prev": {
                right: "100%",
                left: "inherit"
              },
              "&.slick-next": {
                left: "100%"
              }
            },
            ".slick-dots": {
              display: "flex !important",
              justifyContent: ["center", "center", "flex-start"],
              position: "absolute",
              bottom: ["-32px", "-32px", "20px"],
              left: ["50%", "50%", "150px"],
              width: ["auto", "auto", "100%"],
              transform: ["translateX(-50%)", "translateX(-50%)", "none"],
              zIndex: 1,
              li: {
                mx: "6px",
                width: "auto",
                height: "auto",
                button: {
                  p: 0,
                  width: "12px",
                  height: "12px",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: ["primary", "primary", "white"],
                  borderRadius: "50%",
                  bg: "transparent",
                  opacity: ".6",
                  "&:hover": {
                    bg: ["primary", "primary", "white"]
                  },
                  "&:before": {
                    display: "none"
                  }
                },
                "&.slick-active": {
                  button: {
                    bg: ["primary", "primary", "white"],
                    opacity: "1"
                  }
                }
              }
            }
          }
        }}
      >
        <Slider ref={slider} {...settings}>
          {data.slides.map((slide, i) => (
            <Box
              key={i}
              sx={{
                position: "relative",
                ".gatsby-image-wrapper": {
                  width: "100% !important"
                  // height: "100% !important"
                }
              }}
            >
              <CustomImage img={slide.image} />
              <Box
                sx={{
                  position: "absolute",
                  display: "block",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  a: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    textIndent: "-9999px",
                    overflow: "hidden"
                  }
                }}
              >
                <Link href={slide.href}>Lien vers {slide.title}</Link>
              </Box>
            </Box>
          ))}
        </Slider>
        <Flex
          justifyContent={["space-between", "space-between", "flex-start"]}
          maxWidth="220px"
          mx={["auto", "auto", 0]}
          sx={{
            position: ["relative", "relative", "absolute"],
            bottom: "0",
            left: [0, 0, 6],
            background: ["white", "white", theme.colors.grayLight]
          }}
        >
          <Button onClick={() => slider?.current?.slickPrev()} type="button" variant="nav">
            <Icon icon="chevron-prev" size={20} />
          </Button>
          <Button onClick={() => slider?.current?.slickNext()} type="button" variant="nav" ml="1px">
            <Icon icon="chevron-next" size={20} />
          </Button>
        </Flex>
      </Box>
    </Wrapper>
  );
};

Slideshow.propTypes = {
  data: PropTypes.shape({
    slides: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      grayLight: PropTypes.any
    })
  })
};

export default withTheme(Slideshow);

export const query = graphql`
  fragment SlideshowBlock on MarkdownRemarkFrontmatterBlockscollection {
    slides {
      title
      href
      image {
        childImageSharp {
          fluid(maxHeight: 444, maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

import { graphql } from "gatsby";
import Products from "./Products";

export default Products;

export const query = graphql`
  fragment ProductsBlock on MarkdownRemarkFrontmatterBlockscollection {
    supTitle
    title
  }
`;

import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CustomBreadcrumb from "../../components/UI/CustomBreadcrumb";
import Content from "./Content";
import PropTypes from "prop-types";

const PageTemplate = ({ data, path, pageContext }) => {
  const infos = data.markdownRemark.frontmatter;
  infos.thumbnail = infos.thumbnail ? infos.thumbnail.publicURL : null;
  infos.headerImage = infos.headerImage ? infos.headerImage : null;
  return (
    <Layout
      {...infos}
      path={path}
      slug={pageContext.slug}
      headerImage={infos.headerImage}
      {...pageContext}
    >
      {pageContext.slug && !pageContext.slug.includes("index") && (
        <CustomBreadcrumb crumbLabel={infos.title} {...pageContext} />
      )}
      <Content data={infos} slug={pageContext.slug} />
    </Layout>
  );
};
PageTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    breadcrumb: PropTypes.any,
    i18nPaths: PropTypes.any,
    lang: PropTypes.any,
    slug: PropTypes.any
  }),
  path: PropTypes.any
};
export default PageTemplate;

export const pageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        thumbnail {
          publicURL
        }
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        blockscollection {
          ...Blocks
        }
      }
    }
  }
`;

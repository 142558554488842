import PropTypes from "prop-types";
import React, { useContext } from "react";
import Container from "./Container";
import { Box, Flex } from "rebass/styled-components";
import { Link } from "gatsby";
import HomeIcon from "../../../static/img/icons/home.svg";
import Icon from "../UI/Icon";
import { LangContext } from "../Layout/langContext";

const CustomBreadcrumb = ({ crumbLabel, breadcrumb }) => {
  const { currentLanguage } = useContext(LangContext);
  return (
    <Container>
      {breadcrumb.crumbs.length > 0 && (
        <Box
          as="nav"
          pt={4}
          fontSize={1}
          sx={{
            a: {
              color: "gray",
              textDecoration: "none",
              "&:hover": {
                color: "text"
              }
            }
          }}
        >
          <Flex alignItems="center" as="ul" p={0} sx={{ listStyleType: "none" }}>
            {breadcrumb.crumbs.map((crumb, i) => {
              let isLast = i === breadcrumb.crumbs.length - 1;
              let label =
                isLast && crumbLabel
                  ? crumbLabel
                  : crumb.pathname == "/fr"
                  ? "Accueil"
                  : crumb.crumbLabel;
              let labelCap = label.charAt(0).toUpperCase() + label.slice(1);
              let isHomeFR = currentLanguage === "fr" && crumb.pathname === "/";
              return (
                <React.Fragment key={i}>
                  {!isLast && !isHomeFR && (
                    <Flex alignItems="center" as="li">
                      <Link to={crumb.pathname} title={labelCap}>
                        {labelCap}
                      </Link>
                    </Flex>
                  )}
                  {isLast && (
                    <Flex alignItems="center" as="li">
                      {labelCap}
                    </Flex>
                  )}
                  {!isLast && !isHomeFR && (
                    <Flex alignItems="center" px={2} as="span" color="gray">
                      <Icon icon="chevron-next" size={14} />
                    </Flex>
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        </Box>
      )}
    </Container>
  );
};

CustomBreadcrumb.propTypes = {
  breadcrumb: PropTypes.object,
  crumbLabel: PropTypes.string
};

CustomBreadcrumb.defaultProps = {
  breadcrumb: {
    crumbs: []
  },
  crumbLabel: ""
};

export default CustomBreadcrumb;

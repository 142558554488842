import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { Box, Heading } from "rebass/styled-components";
import Wrapper from "../Wrapper";

const FacebookPageTimeline = ({ data }) => {
  React.useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
  return (
    <Wrapper data={data}>
      <Box>
        {data.title && (
          <Heading as="h3" variant="h4">
            {data.title}
          </Heading>
        )}
        <Box textAlign="center" mt={4}>
          <div id="fb-root"></div>
          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v12.0"
            nonce="WZKbYoFH"
          ></script>
          <div
            className="fb-page"
            data-href="https://www.facebook.com/VanuatuBeverageLtd"
            data-tabs="timeline"
            data-width="500"
            data-height="1000"
            data-small-header="true"
            data-adapt-container-width="true"
            data-hide-cover="true"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/VanuatuBeverageLtd"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/VanuatuBeverageLtd">Vanuatu Beverage Limited</a>
            </blockquote>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

FacebookPageTimeline.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any
  })
};
export default FacebookPageTimeline;

export const query = graphql`
  fragment FacebookPageTimelineBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
  }
`;

import React from "react";
import Blocks from "../../components/Blocks";
import PropTypes from "prop-types";

const PageTemplate = ({ data, slug }) => {
  return <Blocks blocks={data.blockscollection} slug={slug} />;
};
PageTemplate.propTypes = {
  data: PropTypes.object,
  slug: PropTypes.string
};
export default PageTemplate;

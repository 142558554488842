import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Button, Text } from "rebass/styled-components";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";
import blockcollection from "../../../cms/data/settings/blockcollections.json";
import { withTheme } from "styled-components";

const Parallax = ({ data, theme }) => {
  var bgImgDefaultUrl = "";
  var elemFields = [];
  blockcollection.blocks.map(function(elem, index) {
    if (elem.name === "Parallax") {
      elemFields = elem.fields;
    }
  });
  elemFields.map(function(item, index) {
    {
      item.type === "image";
      bgImgDefaultUrl = item.default;
    }
  });
  const bgIMG = data.bgImg ? data.bgImg.childImageSharp.fluid.srcWebp : null;
  return (
    <Wrapper data={data}>
      <Box
        py={[8, 8, 11]}
        px={[10, 11, 12]}
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "big",
          "&:before, &:after": {
            content: "''",
            position: "absolute",
            top: 0,
            height: "100%"
          },
          "&:before": {
            left: "50%",
            width: "102vw",
            transform: "translateX(-50%)",
            backgroundImage: "url(" + bgIMG + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            zIndex: 1
          },
          "&:after": {
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors.primary,
            opacity: "0.75",
            zIndex: 2
          },
          "& > *": {
            position: "relative",
            zIndex: 3,
            color: "white"
          }
        }}
      >
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          justifyContent="space-between"
          variant="grid"
          flexWrap="wrap"
        >
          <Box
            width={[1, 1, 1, "100%"]}
            variant="gridItem"
            sx={{
              flex: "1",
              "p:last-child": {
                mb: 0
              }
            }}
          >
            <Flex flexDirection="column" justifyContent="center" height="100%">
              {data.supTitle && (
                <Text variant="supTitle" color="white">
                  {data.supTitle}
                </Text>
              )}
              {data.title && (
                <Box>
                  <Heading as="h2" variant="variants.blockTitleWhite" mb="0">
                    {data.title}
                  </Heading>
                </Box>
              )}
              {data.content && (
                <Box mt={4} maxWidth="586px">
                  <Markdown>{data.content}</Markdown>
                </Box>
              )}
            </Flex>
          </Box>
          <Flex
            mt={[4, 4, 4, 0]}
            width="auto"
            justifyContent={["flex-start", "flex-start", "flex-start", "flex-end"]}
            alignItems="center"
            variant="gridItem"
            sx={{
              a: {
                textDecoration: "none"
              }
            }}
          >
            <Link href={data.cta.href}>
              <Button variant="invert">
                {data.cta.label}
                <Icon icon="arrow-next" />
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Wrapper>
  );
};

Parallax.propTypes = {
  data: PropTypes.shape({
    supTitle: PropTypes.any,
    title: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any,
    bgImg: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any
    })
  })
};

export default withTheme(Parallax);

export const query = graphql`
  fragment ParallaxBlock on MarkdownRemarkFrontmatterBlockscollection {
    supTitle
    title
    content
    front {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    subject {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    beforebackground {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    background {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

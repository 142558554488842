import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Flex, Text } from "rebass/styled-components";
import Forms from "../../Forms";
import Infos from "./Infos";

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormContact = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  return (
    <Wrapper data={data}>
      {hasData && (
        <Flex variant="grid">
          <Box variant="gridItem" mb={10} width={[1, 1, 1 / 2]}>
            <Infos />
          </Box>
          <Box variant="gridItem" width={[1, 1, 1 / 2]}>
            <Forms data={data.form.frontmatter} />
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormContact.propTypes = {
  data: PropTypes.shape({
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    })
  })
};
export default FormContact;

export const query = graphql`
  fragment FormContactBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
  }
`;

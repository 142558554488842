import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
import Slideshow from "./Slideshow";
import Header from "./Header";
import Markup from "./Markup";
import Features from "./Features";
import Gallery from "./Gallery";
import Carousel from "./Carousel";
import Numbers from "./Numbers";
import Form from "./Form";
import FormContact from "./FormContact";
import FormMap from "./FormMap";
import Separator from "./Separator";
import Cards from "./Cards";
import ImgMarkupTwoCol from "./ImgMarkupTwoCol";
import Banner from "./Banner";
import Parallax from "./Parallax";
import Products from "./Products";
import FacebookPageTimeline from "./FacebookPageTimeline";

const blocksComponents = {
  Slideshow: Slideshow,
  Header: Header,
  Markup: Markup,
  Features: Features,
  Gallery: Gallery,
  Carousel: Carousel,
  Numbers: Numbers,
  Form: Form,
  FormContact: FormContact,
  FormMap: FormMap,
  Separator: Separator,
  Cards: Cards,
  ImgMarkupTwoCol: ImgMarkupTwoCol,
  Banner: Banner,
  Parallax: Parallax,
  Products: Products,
  FacebookPageTimeline: FacebookPageTimeline
};

const Block = ({ block, slug }) => {
  if (block.blockSettings) {
    if (block.blockSettings.variant) {
      const Variant = loadable(() => import(`./Variants/${block.blockSettings.variant}`));
      return <Variant data={block} slug={slug} />;
    }
  }
  const BlockComp = blocksComponents[block.type];
  if (BlockComp) {
    return <BlockComp data={block} slug={slug} />;
  }
  return <> </>;
};

Block.propTypes = {
  slug: PropTypes.string,
  block: PropTypes.shape({
    blockSettings: PropTypes.shape({
      variant: PropTypes.any
    }),
    type: PropTypes.string
  })
};
export default ({ blocks, slug }) => {
  blocks = blocks || [];
  return blocks.map((blockItem, i) => <Block block={blockItem} key={i} slug={slug} />);
};

export const query = graphql`
  fragment Blocks on MarkdownRemarkFrontmatterBlockscollection {
    type
    blockSettings {
      showTitle
      blockname
      bg
      color
      containerFluid
      maxContainer
      id
      variant
      parallax
      fullHeight
      align
      padding {
        bottom
        top
      }
      bgImg {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    ...SlideshowBlock
    ...HeaderBlock
    ...MarkupBlock
    ...GalleryBlock
    ...CarouselBlock
    ...NumbersBlock
    ...FeaturesBlock
    ...FormBlock
    ...FormContactBlock
    ...FormMapBlock
    ...SeparatorBlock
    ...CardsBlock
    ...ImgMarkupTwoColBlock
    ...BannerBlock
    ...ProductsBlock
    ...FacebookPageTimelineBlock
    ...ParallaxBlock
  }
`;
